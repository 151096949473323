<template>
  <div>
    <v-icon color="orange"
            small
            class="mr-2"
            @click="updateUser"
    >mdi-pencil
    </v-icon>

    <v-icon color="red"
            small
            @click="dialogDelete = true"
            :disabled="dataDelete.length > 0"
            v-if="checkUserPermission('DELETE')"
    > mdi-delete
    </v-icon>

    <dialog-delete :darkmode="darkmode"
                   :dialog-delete="dialogDelete"
                   v-on:closeDelete="cancelDelete"
                   v-on:deleteItemConfirm="deleteItem"
    />

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DialogDelete from "../../../../components/shared/notify/DialogDelete";

export default {
  components: { DialogDelete},
  props: ['darkmode', "item", "params", "dataDelete", "userPermission"],
  data() {
    return {
      validPassword: true,
      user: {
        _id: null,
        user_name: null,
        password: null
      },
      dialogListUser: false,
      loading: false,
      dialogChangePassword: false,
      dialogDelete: false,
      userChild: [],
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ['_id'],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null
      }
    };
  },
  computed: {
    ...mapGetters("$_user", ["getCurrentUser"]),
  },
  methods: {
    async updateUser() {
      await this.fetchCurrentPayOutData(this.item);
      this.fetchPopUp(true);
    },

    cancelDelete() {
      this.dialogDelete = false
    },

    async deleteItem() {
      await this.deleteData({
        vm: this,
        data: {deleteItems: [{_id: this.item._id}]}
      });
      await this.fetchGetAllPayoutUser(this.params)
      this.dialogDelete = false;
    },

    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_weight", ["fetchPopUp", "fetchCurrentPayOutData", "deleteData", "fetchGetAllPayOut",]),
  },
};
</script>
